import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            socketHandlers: {},
        }
    },
    computed: {
        ...mapGetters('map', ['liveUpdatesEnabledConditionally']),
    },
    watch: {
        liveUpdatesEnabledConditionally() {
            this.clearSocketHandlers()
            if (this.liveUpdatesEnabledConditionally) {
                this.addSocketHandlers()
            }
        },
    },
    mounted() {
        Object.keys(this.$options?.sockets ?? {}).forEach(event => {
            this.socketHandlers[event] = this.$options.sockets[event].bind(this)
        })
        if (this.liveUpdatesEnabledConditionally) {
            this.addSocketHandlers()
        }
    },
    beforeDestroy() {
        this.clearSocketHandlers()
    },
    methods: {
        addSocketHandlers() {
            Object.keys(this.socketHandlers).forEach(event => {
                this.$socket?.on(event, this.socketHandlers[event])
            })
        },
        clearSocketHandlers() {
            Object.keys(this.socketHandlers).forEach(event => {
                this.$socket?.off(event, this.socketHandlers[event])
            })
        },
    },
}
